import React, { useEffect, useState, useRef } from "react";
import { Button, Table } from "react-bootstrap";
import { useParams } from "react-router-dom";
import Logo from "assets/Logo.png";
import "../index.css";
import { useLocation } from "react-router-dom";
import htmlToPdfMake from "html-to-pdfmake";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

pdfMake.vfs = pdfFonts.pdfMake.vfs;
export default function Viewinvoice() {
  const contentRef = useRef(null);
  const handleDownload = () => {
    // Create a copy of the PDFContent DOM element and remove the button
    const contentCopy = contentRef.current.cloneNode(true);
    const buttonToRemove = contentCopy.querySelector("#buttonToRemove");
    const button = contentCopy.querySelector("#buttonToRemoves");
    const downloadbutton = contentCopy.querySelector("#buttonToRemovess");
    if (buttonToRemove && button && downloadbutton) {
      buttonToRemove.parentNode.removeChild(buttonToRemove);
      button.parentNode.removeChild(button);
      downloadbutton.parentNode.removeChild(downloadbutton);
    }

    // Convert the modified DOM element to PDF using htmlToPdfMake
    const pdfContent = htmlToPdfMake(contentCopy.outerHTML);

    // Define the document definition object
    const docDefinition = {
      content: pdfContent,
    };

    // Generate the PDF document and download it
    pdfMake.createPdf(docDefinition).download("invoice.pdf");
  };
  const location = useLocation();
  // const datas = location.state.data;
  // const isDisabled = datas === "$0.00";

  const { order_id, invoice_id } = useParams();
  const ord = order_id;
  const inv = invoice_id;
  const [data, setData] = useState([]);
  const [productdata, setProductdata] = useState([]);
  const [addressdata, setAddressdata] = useState([]);
  const [addressdata2, setAddressdata2] = useState([]);
  const [loading, setLoading] = useState(false);
  const BASE_URL = process.env.REACT_APP_API_BASE_URL;
  // const Btndis = () => {
  //   if (data.invoice_status === "Paid" || isDisabled === true) {
  //     return (
  //       <Button
  //         type="submit"
  //         className="ml-5  bd-highlight "
  //         disabled={isDisabled}
  //         onClick={() => {
  //           handleReservedPay();
  //         }}
  //       >
  //         Reserved Pay
  //       </Button>
  //     );
  //   } else {
  //     return (
  //       <Button
  //         type="submit"
  //         className="ml-5  bd-highlight "
  //         onClick={() => {
  //           handleReservedPay();
  //         }}
  //       >
  //         Reserved Pay
  //       </Button>
  //     );
  //   }
  // };

  useEffect(() => {
    const data = {
      order_id: ord,
      invoice_id: inv,
    };
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Bearer " + JSON.parse(sessionStorage.getItem("token")).data.token,
      },
      body: JSON.stringify(data),
    };
    fetch(`${BASE_URL}/invoice/detail`, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        const invd = response.data.invoiceData;
        const product = response.data.orderProducts;
        setData(invd);
        setProductdata(product);
        // console.log(invd);
        // console.log(product);
      });
    const addres = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Bearer " + JSON.parse(sessionStorage.getItem("token")).data.token,
      },
    };
    fetch(
      `${BASE_URL}/miscellaneous/genparams?paramType=invoice_setting`,
      addres
    )
      .then((response) => response.json())
      .then((response) => {
        setAddressdata(response.data.params[0].values[5].paramval);
        setAddressdata2(response.data.params[0].values[2].paramval);
      });
  }, []);

  // const handleSubmit = () => {
  //   const data = {
  //     order_id: `${ord}`,
  //     invoice_id: `${inv}`,
  //   };

  //   const requestOptions = {
  //     method: "POST",
  //     headers: { "Content-Type": "application/json" },
  //     body: JSON.stringify(data),
  //   };
  //   fetch(`${BASE_URL}/paypro/clientpay`, requestOptions)
  //     .then((response) => response.json())

  //     .then((response) => {
  //       // console.log(response);
  //       window.location.assign(
  //         response.link + `&callback_url=https://client.opsways.com/success`
  //       );
  //     });
  // };
  // const handleReservedPay = () => {
  //   const data = {
  //     order_id: `${ord}`,
  //     invoice_id: `${inv}`,
  //     client_id: JSON.parse(sessionStorage.getItem("token")).data.clientuserid,
  //   };

  //   const requestOptions = {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json",
  //       Authorization:
  //         "Bearer " + JSON.parse(sessionStorage.getItem("token")).data.token,
  //     },
  //     body: JSON.stringify(data),
  //   };
  //   fetch(`${BASE_URL}/order/reservedamountorder`, requestOptions)
  //     .then((response) => response.json())

  //     .then((response) => {
  //       // console.log(response.data.message);
  //       alert(response.data.message);
  //       window.location.reload();
  //     });
  // };

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 5000);
  }, []);


  return (
    <>
      <div className="container-fluid" id="yourReactComponent" ref={contentRef}>
        <div className="row container">
          <div className="col-lg-6">
            <div className="d-flex- justify-content-start">
              <img src={Logo} height="100" alt="banner" />
            </div>
          </div>

          <div className="col-lg-6     responive-table-header    ">
            <div className="d-flex flex-column   d-flex justify-content-end">
              <h2  className="bd-highlight">
                Accounts Department
              </h2>
              <h6 className="bd-highlight">
                {addressdata}
                <br />
                info@healway.com, {addressdata2}
              </h6>
            </div>
          </div>
        </div>

        <hr
          style={{
            color: "#ddbc6b",
            height: 10,
          }}
        />
        <div>
          <div className="row">
            <div className="col-lg-6 ">
              <div className="d-flex justify-content-start">
                <h3 >{data.client_name}</h3>
              </div>
            </div>

            <div className="col-lg-6 responive-table-header">
              <div className="d-flex justify-content-end">
                <table>
                  <tr className="table-info-header">
                    <th>Invoice # :</th>
                    <td>{data.invoice_no}</td>
                  </tr>
                  <tr className="table-info-header">
                    <th>Invoice Date :</th>
                    <td>{data.invoice_date}</td>
                  </tr>
                  <tr className="table-info-header">
                    <th>Due Date :</th>
                    <td>{data.due_date}</td>
                  </tr>
                  <tr className="table-info-header">
                    <th>Status:</th>
                    <td>{data.invoice_status}</td>
                  </tr>
                  <tr className="table-info-header">
                    <th>Payment Method:</th>
                    <td>{data.paymentmethod}</td>
                  </tr>
                  <tr className="table-info-header">
                    <th>Duration Period:</th>
                    <td>{data.subscription_period}</td>
                  </tr>
                  <tr className="table-info-header">
                    <th>Amount Payable:</th>
                    <td>{data.order_total}</td>
                  </tr>
                </table>
              </div>
            </div>
          </div>

          <div class="d-flex flex-row bd-highlight justify-content-center align-items-center  mt-3 mb-3">
            <div class="p-2 bd-highlight responive-table-invocie-content">
              <Button
                type="submit bd-highlight "
                id="buttonToRemovess"
                onClick={handleDownload}
              >
                Download Invoice
              </Button>
            </div>
            {/* <div class="p-2 bd-highlight responive-table-invocie-content">
              <Button
                type="submit"
                id="buttonToRemove"
                className=" bd-highlight "
                onClick={() => {
                  handleReservedPay();
                }}
                disabled={
                  data.invoice_status === "Completed" ||
                  data.invoice_status === "Paid" ||
                  isDisabled === true
                }
              >
                Reserved Pay
              </Button>
            </div> */}
            {/* <div class="p-2 bd-highlight responive-table-invocie-content">
              <Button
                type="submit"
                id="buttonToRemoves"
                className="  bd-highlight "
                disabled={
                  data.invoice_status === "Paid" ||
                  data.invoice_status === "Completed"
                }
                onClick={() => {
                  handleSubmit();
                }}
              >
                Pay now
              </Button>
            </div> */}
          </div>
        </div>
        <p className="text-center mt-4 reserved-text-invoice">
          {data.note_down}
        </p>
        <div className="invoice-responsive-375px mt-4 text-center text-black d-flex justify-content-center ">
          <Table bordered className="">
            <thead className="text-black bold table-header  ">
              <tr>
                <th>Item</th>
                <th>Product</th>
                <th>Person</th>
                <th>Date & Time</th>
                <th>Price</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody className="table-success">
              {productdata.map((op) => (
                <tr>
                  <td>1</td>
                  <td>{op.productname}</td>
                  <td>{op.quantity}</td>
                  <td>{op.unitprice}</td>
                  <td>{op.unitprice}</td>
                  <td>{op.totalprice}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
        <div className="text-center mt-5">
          <b>Thank you for your business!</b>
        </div>
      </div>
    </>
  );
}
