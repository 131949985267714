import React, { useState } from "react";
import { NavLink, useHistory } from "react-router-dom";
import Logo from "assets/images/logo.svg";
import "./forget.css";

export default function ResetPassword() {
  const BASE_URL = process.env.REACT_APP_API_BASE_URL;
  const [email, setEmail] = useState();
  const navigate = useHistory();
  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = {
      email,
    };
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(token),
    };
    const response = await fetch(
      `${BASE_URL}/email/forgetpass`,
      requestOptions
    );
    const data = await response.json();
    sessionStorage.setItem("token", JSON.stringify(data));
  };
  return (
    <div>
      {" "}
      <div className="m-auto">
        <div className="main-login-page">
          <div className="pageSwitcher  btnSwitcher-pg">
            <button
              className="formFieldButton login-btn-form "
              onClick={() => navigate.goBack()}
            >
              Back
            </button>
          </div>

          <div className="formCenter">
            <form className="formFields" onSubmit={handleSubmit}>
              <div className="formField flex-column d-flex justify-content-start">
                <label className="formFieldLabel " htmlFor="email">
                  E-Mail Address
                </label>
                <input
                  type="email"
                  id="email"
                  className="formFieldInput"
                  placeholder="Enter your email"
                  value={email}
                  size="lg"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>

              <div className="formField btn-field  ">
                <a href="/" className="forget-buttons login-btn-form m-2">
                  Cancel
                </a>

                <NavLink
                  to="/generateotp"
                  className="formFieldButton login-btn-form m-2"
                >
                  Generate OTP{" "}
                </NavLink>
              </div>
            </form>
          </div>
        </div>
        <div className="footer-login">
          <a href="https://react.opsways.com/" target="_blank ">
            <img src={Logo} alt="opsways" className="footoer-logo" />
          </a>
        </div>
      </div>
    </div>
  );
}
