import React from "react";
import Invoices from "components/Invoices";
import NoOfSubscribtion from "components/NoSubscribtion";
import ReviewsLayout from "components/ReviewsLayout";
// import WelcomeMessage from "components/WelcomeMessage";
export default function Dashboard() {
  return (
    <div className="container-fluid responsive-385">
      {/* <WelcomeMessage /> */}
      <div className="row">
        <div className="col-lg-7 stretch-card mt-3">
          <Invoices />
        </div>
        <div className="col-lg-5 stretch-card mt-3">
          <NoOfSubscribtion />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-7 stretch-card mt-3">
          <ReviewsLayout />
        </div>
        <div className="col-lg-5 stretch-card mt-3">
          {/* <NoOfSubscribtion /> */}
        </div>
      </div>
    </div>
  );
}
