import React from "react";
import { Carousel, Card } from "react-bootstrap";
import IMG from "assets/images/logo.svg";

export default function Offers() {
  return (
    <div className="row">
      <div className="col-xl-4 stretch-card">
        <div className="card card-top ">
          <div className="card-body ">
            <h3>
              <span className="menu-icon">
                <i
                  className="mdi mdi-bell-ring"
                  style={{ color: "#ddbc6b" }}
                ></i>{" "}
              </span>
              Offers
            </h3>
            <Carousel>
              <Carousel.Item interval={5000}>
                <Card style={{ width: "auto", height: "auto" }}>
                  <Card.Body>
                    <Card.Title
                      style={{ color: "#ddbc6b", SfontSize: 22 }}
                      className="mdi mdi-cloud"
                    >
                      {" "}
                      25%
                    </Card.Title>
                    <h4>Give the gift of wellness</h4>
                    <img
                      src={IMG}
                      style={{ height: "100%", width: "100%" }}
                      alt="promotion"
                    />
                    <h6>
                      We make our gift cards super easy to give and use by
                      matching them to the cost of some of our favorite
                      services. Send instantly or schedule ahead for special
                      occasions.
                    </h6>
                    <Card.Link href="/offers/offers"></Card.Link>
                  </Card.Body>
                </Card>
              </Carousel.Item>
              <Carousel.Item interval={5000}>
                <Card style={{ width: "auto", height: "auto" }}>
                  <Card.Body>
                    <Card.Title
                      style={{ color: "#ddbc6b", SfontSize: 22 }}
                      className="mdi mdi-cloud"
                    >
                      {" "}
                      50%
                    </Card.Title>
                    <h4>Give the gift of wellness</h4>
                    <img
                      src={IMG}
                      style={{ height: "100%", width: "100%" }}
                      alt="promotion"
                    />
                    <h6>
                      We make our gift cards super easy to give and use by
                      matching them to the cost of some of our favorite
                      services. Send instantly or schedule ahead for special
                      occasions.
                    </h6>
                    <Card.Link href="/offers/offers"></Card.Link>
                  </Card.Body>
                </Card>
              </Carousel.Item>
              <Carousel.Item interval={5000}>
                <Card style={{ width: "auto", height: "auto" }}>
                  <Card.Body>
                    <Card.Title
                      style={{ color: "#ddbc6b", SfontSize: 22 }}
                      className="mdi mdi-cloud"
                    >
                      {" "}
                      100%
                    </Card.Title>
                    <h4>Give the gift of wellness</h4>
                    <img
                      src={IMG}
                      style={{ height: "100%", width: "100%" }}
                      alt="promotion"
                    />
                    <h6>
                      We make our gift cards super easy to give and use by
                      matching them to the cost of some of our favorite
                      services. Send instantly or schedule ahead for special
                      occasions.
                    </h6>
                    <Card.Link href="/offers/offers"></Card.Link>
                  </Card.Body>
                </Card>
              </Carousel.Item>
            </Carousel>
          </div>
        </div>
      </div>
      <div className="col-xl-4 stretch-card">
        <div className="card card-top ">
          <div className="card-body ">
            <h3>
              <span className="menu-icon">
                <i
                  className="mdi mdi-bell-ring"
                  style={{ color: "#ddbc6b" }}
                ></i>{" "}
              </span>
              Offers
            </h3>
            <Carousel>
              <Carousel.Item interval={5000}>
                <Card style={{ width: "auto", height: "auto" }}>
                  <Card.Body>
                    <Card.Title
                      style={{ color: "#ddbc6b", SfontSize: 22 }}
                      className="mdi mdi-cloud"
                    >
                      {" "}
                      25%
                    </Card.Title>
                    <h4>Give the gift of wellness</h4>
                    <img
                      src={IMG}
                      style={{ height: "100%", width: "100%" }}
                      alt="promotion"
                    />
                    <h6>
                      We make our gift cards super easy to give and use by
                      matching them to the cost of some of our favorite
                      services. Send instantly or schedule ahead for special
                      occasions.
                    </h6>
                    <Card.Link href="/offers/offers"></Card.Link>
                  </Card.Body>
                </Card>
              </Carousel.Item>
              <Carousel.Item interval={5000}>
                <Card style={{ width: "auto", height: "auto" }}>
                  <Card.Body>
                    <Card.Title
                      style={{ color: "#ddbc6b", SfontSize: 22 }}
                      className="mdi mdi-cloud"
                    >
                      {" "}
                      50%
                    </Card.Title>
                    <h4>Give the gift of wellness</h4>
                    <img
                      src={IMG}
                      style={{ height: "100%", width: "100%" }}
                      alt="promotion"
                    />
                    <h6>
                      We make our gift cards super easy to give and use by
                      matching them to the cost of some of our favorite
                      services. Send instantly or schedule ahead for special
                      occasions.
                    </h6>
                    <Card.Link href="/offers/offers"></Card.Link>
                  </Card.Body>
                </Card>
              </Carousel.Item>
              <Carousel.Item interval={5000}>
                <Card style={{ width: "auto", height: "auto" }}>
                  <Card.Body>
                    <Card.Title
                      style={{ color: "#ddbc6b", SfontSize: 22 }}
                      className="mdi mdi-cloud"
                    >
                      {" "}
                      100%
                    </Card.Title>
                    <h4>Give the gift of wellness</h4>
                    <img
                      src={IMG}
                      style={{ height: "100%", width: "100%" }}
                      alt="promotion"
                    />
                    <h6>
                      We make our gift cards super easy to give and use by
                      matching them to the cost of some of our favorite
                      services. Send instantly or schedule ahead for special
                      occasions.
                    </h6>
                    <Card.Link href="/offers/offers"></Card.Link>
                  </Card.Body>
                </Card>
              </Carousel.Item>
            </Carousel>
          </div>
        </div>
      </div>
      <div className="col-xl-4 stretch-card">
        <div className="card card-top ">
          <div className="card-body ">
            <h3>
              <span className="menu-icon">
                <i
                  className="mdi mdi-bell-ring"
                  style={{ color: "#ddbc6b" }}
                ></i>{" "}
              </span>
              Offers
            </h3>
            <Carousel>
              <Carousel.Item interval={5000}>
                <Card style={{ width: "auto", height: "auto" }}>
                  <Card.Body>
                    <Card.Title
                      style={{ color: "#ddbc6b", SfontSize: 22 }}
                      className="mdi mdi-cloud"
                    >
                      {" "}
                      25%
                    </Card.Title>
                    <h4>Give the gift of wellness</h4>
                    <img
                      src={IMG}
                      style={{ height: "100%", width: "100%" }}
                      alt="promotion"
                    />
                    <h6>
                      We make our gift cards super easy to give and use by
                      matching them to the cost of some of our favorite
                      services. Send instantly or schedule ahead for special
                      occasions.
                    </h6>
                    <Card.Link href="/offers/offers"></Card.Link>
                  </Card.Body>
                </Card>
              </Carousel.Item>
              <Carousel.Item interval={5000}>
                <Card style={{ width: "auto", height: "auto" }}>
                  <Card.Body>
                    <Card.Title
                      style={{ color: "#ddbc6b", SfontSize: 22 }}
                      className="mdi mdi-cloud"
                    >
                      {" "}
                      50%
                    </Card.Title>
                    <h4>Give the gift of wellness</h4>
                    <img
                      src={IMG}
                      style={{ height: "100%", width: "100%" }}
                      alt="promotion"
                    />
                    <h6>
                      We make our gift cards super easy to give and use by
                      matching them to the cost of some of our favorite
                      services. Send instantly or schedule ahead for special
                      occasions.
                    </h6>
                    <Card.Link href="/offers/offers"></Card.Link>
                  </Card.Body>
                </Card>
              </Carousel.Item>
              <Carousel.Item interval={5000}>
                <Card style={{ width: "auto", height: "auto" }}>
                  <Card.Body>
                    <Card.Title
                      style={{ color: "#ddbc6b", SfontSize: 22 }}
                      className="mdi mdi-cloud"
                    >
                      {" "}
                      100%
                    </Card.Title>
                    <h4>Give the gift of wellness</h4>
                    <img
                      src={IMG}
                      style={{ height: "100%", width: "100%" }}
                      alt="promotion"
                    />
                    <h6>
                      We make our gift cards super easy to give and use by
                      matching them to the cost of some of our favorite
                      services. Send instantly or schedule ahead for special
                      occasions.
                    </h6>
                    <Card.Link href="/offers/offers"></Card.Link>
                  </Card.Body>
                </Card>
              </Carousel.Item>
            </Carousel>
          </div>
        </div>
      </div>
    </div>
  );
}
