import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import "./index.css";
import Preloader from "assets/images/preloader.gif";
import { Icon } from "@iconify/react";
export default function NoOfSubscribtion() {
  const [subscription, setSubscription] = useState([]);
  const [loading, setloading] = useState(undefined);
  const [completed, setcompleted] = useState(undefined);
  const BASE_URL = process.env.REACT_APP_API_BASE_URL;

  useEffect(() => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Bearer " + JSON.parse(sessionStorage.getItem("token")).data.token,
      },
    };
    fetch(
      `${BASE_URL}/subscription/fetch/${
        JSON.parse(sessionStorage.getItem("token")).data.clientuserid
      }`,
      requestOptions
    )
      .then((response) => response.json())
      .then((response) => {
        setSubscription(response.data.clientSubscription);
        // console.log(response.data.clientSubscription);
        setTimeout(() => {
          setcompleted(true);
        }, 1000);
        setloading(false);
      });
  }, []);

  const history = useHistory();
  return (
    <>
      <div className="col-xl-12 stretch-card">
        <div className="card card-top">
          <div className="card-body">
            <h3>
              <span className="menu-icon">
                <i
                  className="mdi mdi-wunderlist"
                  style={{ color: "#ddbc6b" }}
                ></i>{" "}
              </span>{" "}
              Active Subscriptions
            </h3>
            <div className="table-responsive text-center">
              <table className="table table-hover" style={{ overflow: "auto" }}>
                <thead className="table table-header">
                  <tr>
                    <th> Product Name </th>
                    <th> Subscription </th>
                    <th> Status </th>
                  </tr>
                </thead>
                {!completed ? (
                  <>
                    {!loading ? (
                      <div className="d-flex justify-content-md-center">
                        <div className="spinner text-center ">
                          {/* <img
                            src={Preloader}
                            alt="preloader"
                            className="img-lodder"
                          /> */}
                          <br />
                          <span className="span_title">
                            <h6>Loading </h6>
                          </span>
                          <span>
                            <Icon
                              icon="el:refresh"
                              color="#33a280"
                              width={20}
                              height={20}
                            />
                          </span>
                          <br />
                        </div>
                      </div>
                    ) : (
                      <div className="completed">&#x2713;</div>
                    )}
                  </>
                ) : (
                  <tbody className="table-body">
                    {subscription
                      .slice(0, 5)
                      .reverse()
                      .map((mess) => (
                        <tr className="table-success">
                          <td>{mess.productname}</td>
                          <td>{mess.number_of_orders}</td>
                          <td>
                            <label
                              className="badge badge-success"
                              style={{
                                backgroundColor: mess.is_active
                                  ? "#ddbc6b"
                                  : "red",
                                // marginTop: 10,
                              }}
                            >
                              {mess.is_active ? "Active" : "In Active"}
                            </label>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                )}
              </table>
              <div className="d-flex justify-content-end mt-3">
                <div
                  class="badge badge-primary"
                  style={{ cursor: "pointer" }}
                  onClick={() => history.push("/subscription/subscription")}
                >
                  Load more ...{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
