import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

function ForgetPasswordModal() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [generate_code, setGenerate_code] = useState("");
  const [generateCodeError , setgenerateCodeError] = useState("")
  const [validated, setValidated] = useState(false);
  // const [errorMessage, setErrorMessage] = useState("");
  const BASE_URL = process.env.REACT_APP_API_BASE_URL;
  useEffect(() => {
    const data = {
      email: JSON.parse(sessionStorage.getItem("token")).data.email,
    };
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Bearer " + JSON.parse(sessionStorage.getItem("token")).data.token,
      },
      body: JSON.stringify(data),
    };
    fetch(`${BASE_URL}/email/forgetpass`, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          alert(response.data);
        }
      });
  }, []);
  useEffect(() => {}, []);

  const handleSubmit = (e) => {
      e.preventDefault();
      if (!generate_code) {
        setgenerateCodeError("Please enter OTP");
        return;
      }
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Bearer " + JSON.parse(sessionStorage.getItem("token")).data.token,
      },
    };
    fetch(
      `${BASE_URL}/client/listclientsetting/${
        JSON.parse(sessionStorage.getItem("token")).data.clientuserid
      }`,
      requestOptions
    )
      .then((response) => response.json())
      .then((response) => {
        const Otp = response.data.listClientUsersSetting[0].generate_code;
        if (generate_code === Otp) {
          // console.log("OTP confirmed!");
        } else {
         setgenerateCodeError("Incorrect OTP code entered");
        }
        const data = {
          generate_code,
        };
        const requestOptions = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(data),
        };
        fetch(`${BASE_URL}/email/emailstatus`, requestOptions)
          .then((response) => response.json())
          .then((response) => {
            alert(response.data);
            window.location = "/createpassword";
          });
      });

setGenerate_code("");
setgenerateCodeError(false);
  };
  return (
    <>
      <Button variant="primary" onClick={handleShow}>
        Reset Password
      </Button>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header
        //  closeButton
        >
          <Modal.Title>OTP</Modal.Title>
        </Modal.Header>
        <Form noValidate validated={validated}>
          <Modal.Body>
            <Form.Group controlId="validationCustom01">
              <Form.Label>Confirm OTP</Form.Label>
              <Form.Control
                type="text"
                required
                placeholder="Enter Your OTP"
                // onChange={(e) => setGenerate_code(e.target.value)}
                onChange={(e) => {
                  setGenerate_code(e.target.value);
                  setgenerateCodeError(false); // clear error message when user selects a subscription
                }}
              />
              <strong>Please check your email to enter otp here</strong>{" "}
              {generateCodeError && (
                <div className="errorMessage">{generateCodeError}</div>
              )}
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="danger" onClick={handleClose}>
              Close
            </Button>
            <Button onClick={handleSubmit}>Submit</Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}

export default ForgetPasswordModal;
