import React from "react";
import "../../App.scss";
import Navbar from "components/Layout/Navbar";
import Sidebar from "components/Layout/Sidebar";
import Footer from "components/Layout/Footer";

const AppLayout = (props) => {
  return (
    <div className="container-scroller">
      <Sidebar />
      <div className="container-fluid page-body-wrapper">
        <Navbar />
        <div className="main-panel">
          <div className="content-wrapper">{props.children}</div>
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default AppLayout;
