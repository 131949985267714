import PasswordChecklist from "react-password-checklist";
import React, { useEffect, useState } from "react";
import ForgetPasswordModal from "components/Modal/ForgetPasswordModal";
import "./index.css";
import {
  Accordion,
  Button,
  Card,
  Form,
} from "react-bootstrap";

function CustomToggle({ children, eventKey }) {
  return <></>;
}
export default function Settings() {

  const [client, setClient] = useState([]);
  const [clientimage, setClientimage] = useState([]);
  const [image, setImage] = useState([]);
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [active, setActive] = useState(false);
  const [passwordAgain, setPasswordAgain] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);
  const BASE_URL = process.env.REACT_APP_API_BASE_URL;
  // console.log(selectedImage);
  useEffect(() => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Bearer " + JSON.parse(sessionStorage.getItem("token")).data.token,
      },
    };
    fetch(
      `${BASE_URL}/client/listclientsetting/${
        JSON.parse(sessionStorage.getItem("token")).data.clientuserid
      }`,
      requestOptions
    )
      .then((response) => response.json())
      .then((response) => {
        setClient(response.data.listClientUsersSetting[0]);
        setImage(response.data.listClientUsersSetting);
        // console.log(response.data.listClientUsersSetting);
      });
  }, []);
  useEffect(() => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Bearer " + JSON.parse(sessionStorage.getItem("token")).data.token,
      },
    };
    fetch(`${BASE_URL}/image/list`, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        setClientimage(response.data.images);
        // console.log(response.data.images);
      });
  }, []);
  const handleforgotPassword = () => {
    const data = {
      email: JSON.parse(sessionStorage.getItem("token")).data.email,
      password,
    };
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    };
    fetch(`${BASE_URL}/email/createnewpassword`, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          alert(response.data);
        }
      });
  };
  const handleUpdateImage = () => {
    const data = {
      client_id: JSON.parse(sessionStorage.getItem("token")).data.clientuserid,
      imageid: selectedImage,
    };
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Bearer " + JSON.parse(sessionStorage.getItem("token")).data.token,
      },
      body: JSON.stringify(data),
    };
    fetch(`${BASE_URL}/image/edit`, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        // console.log(response.data);
        alert(response.data.message);
        window.location.reload();
      });
  };

  return (
    <>
      <div>
        <div className="row">
          <div className="col-lg-6 col-sm-6 col-xs-6 grid-margin">
            <div className="card card-left ">
              <div className="card-body" style={{ marginTop: -9 }}>
                <div className="row">
                  <div className="col-lg-12 grid-margin text-center">
                    {image.map((meg) => (
                      <img
                        width={222}
                        height={222}
                        className=" ml-auto rounded-circle "
                        src={require(`assets/images/faces/${meg.imageurl}`)}
                        alt="{client.imageid}"
                      />
                    ))}
                  </div>
                  <br />
                </div>
                <div className="row">
                  <div className="col-lg-6 col-sm-6 col-xs-6 grid-margin">
                    <CustomToggle
                      className="mt-3"
                      eventKey="0"
                      style={{ backgroundColor: "#ddbc6b" }}
                    />

                    <Accordion>
                      <Card>
                        <Accordion.Collapse eventKey="0">
                          <Card.Body>
                            <Form className="php-email-form">
                              <div>
                                <div className="password col-lg-12 col-sm-12 col-xs-12 mt-3">
                                  <label>
                                    <b>Password</b>
                                  </label>
                                  <div className="input-group">
                                    <input
                                      type={showPassword ? "text" : "password"}
                                      className="form-control"
                                      name="password"
                                      id="password"
                                      value={password}
                                      placeholder="Password"
                                      maxLength={25}
                                      onChange={(e) =>
                                        setPassword(e.target.value)
                                      }
                                    />
                                  </div>
                                </div>
                                <div className="password col-lg-12 col-sm-12 col-xs-12 mt-3">
                                  <label>
                                    <b>Confirm Password</b>
                                  </label>
                                  <div className="input-group">
                                    <input
                                      type={showPassword ? "text" : "password"}
                                      className="form-control "
                                      name="password"
                                      id="password"
                                      value={passwordAgain}
                                      maxLength={25}
                                      placeholder="Confirm Password"
                                      onChange={(e) =>
                                        setPasswordAgain(e.target.value)
                                      }
                                    />
                                  </div>
                                </div>
                                <hr />

                                <PasswordChecklist
                                  rules={[
                                    "minLength",
                                    "specialChar",
                                    "number",
                                    "capital",
                                    "match",
                                  ]}
                                  minLength={8}
                                  value={password}
                                  valueAgain={passwordAgain}
                                  onChange={(isValid) => {}}
                                />
                              </div>
                              <hr />
                              <br />
                              <br />
                              <div className="text-center">
                                <button
                                  type="submit"
                                  className="btn btn-primary theme-btn mx-auto"
                                  onClick={() => {
                                    handleforgotPassword();
                                  }}
                                >
                                  Submit
                                </button>
                              </div>
                            </Form>
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>
                    </Accordion>
                  </div>

                  <div className="d-flex justify-content-cente m-auto d-flex align-items-center d-flex flex-wrap  ">
                    <div className="row responive-430 ">
                      <div className="col-6 responsive-margin">
                        <Button
                          className="avatar-btn"
                          onClick={() => setActive(!active)}
                        >
                          Select Your Avatar
                        </Button>
                      </div>
                      <div className="col-6 responsive-margin ">
                        <Button className="avatar-btn">
                          <ForgetPasswordModal />
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="text-center">
                  {active ? (
                    <Card className="mt-2">
                      <Card.Body>
                        <div className="hello card-title-ui d-flex justify-content-center">
                          <Card.Header className="cardHeader-css">
                            <Card.Title className="card-sub-heading">
                              Please Select Your Avatar
                            </Card.Title>
                          </Card.Header>

                          {clientimage.map((item) => (
                            <div>
                              <div className="row">
                                <div className="col-lg-2">
                                  <div className="float-left">
                                    <Button className="bg-white avatar-hover">
                                      <img
                                        src={require(`assets/images/faces/${item.imageurl}`)}
                                        key={item.imageid}
                                        style={{
                                          width: "40px",
                                          height: "40px",
                                        }}
                                        alt="name"
                                        onClick={() =>
                                          setSelectedImage(item.imageid)
                                        }
                                      />
                                    </Button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </Card.Body>
                      <Card.Footer>
                        <Button
                          className="float-right"
                          onClick={handleUpdateImage}
                        >
                          Submit
                        </Button>
                      </Card.Footer>
                    </Card>
                  ) : null}
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-6 col-sm-6 col-xs-6">
            <div className="card card-left">
              <div className="card-body">
                <h4 className="card-title mt-2">Account Details </h4>
                <div className="row mt-5">
                  <div
                    className="col-lg-4 col-sm-4 col-xs-4"
                    style={{ fontSize: 12 }}
                  >
                    <b>Full Name:</b>{" "}
                  </div>
                  <div className="col-lg-8 col-sm-8 col-xs-8">
                    <p>
                      {client.client_name} {client.last_name}
                    </p>{" "}
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-4" style={{ fontSize: 12 }}>
                    <b>Email Address:</b>{" "}
                  </div>
                  <div className="col-lg-8">
                    <p>{client.email}</p>{" "}
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-4" style={{ fontSize: 12 }}>
                    <b>Mobile Phone:</b>{" "}
                  </div>
                  <div className="col-lg-8">
                    <p>{client.mobile_phone}</p>{" "}
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-4" style={{ fontSize: 12 }}>
                    <b>Company Name:</b>{" "}
                  </div>
                  <div className="col-lg-8">
                    <p>{client.companyname}</p>{" "}
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-4" style={{ fontSize: 12 }}>
                    <b>Location:</b>{" "}
                  </div>
                  <div className="col-lg-8">
                    <p>{client.state}</p>{" "}
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-4" style={{ fontSize: 12 }}>
                    <b>Address:</b>{" "}
                  </div>
                  <div className="col-lg-8">
                    <p>{client.address}</p>{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
