import { HashRouter as Router, Route } from "react-router-dom";
import React from "react";
import Login from "../Login";
import "../Login/Login.css";
import { Col, Container, Row } from "react-bootstrap";
import ResetPassword from "../ForgetPassword";
import Confirmation from "../ForgetGenerateOTP";
import CreateNewPassword from "../CreateNewPassword";
import OtpConfirm from "../OtpConfirm";

export default function Main() {
  return (
    <div className="main-cont">
      <Container fluid>
        {" "}
        <Router>
          <div className="App d-flex ">
            <div className="wrapper">
              <div className="appForm form-padding ">
                <Route exact path="/" component={Login} />
                <Route path="/resetpassword" component={ResetPassword} />
                <Route path="/generateotp" component={Confirmation} />
                <Route path="/createnew" component={CreateNewPassword} />
                <Route path="/otpconfirm" component={OtpConfirm} />
              </div>
            </div>
          </div>
        </Router>
      </Container>
    </div>
  );
}
