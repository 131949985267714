import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import Logo from "assets/images/logo.svg";
import "../Login/Login.css";
export default function OtpConfirm() {
  const BASE_URL = process.env.REACT_APP_API_BASE_URL;
  const [generate_code, setGenerate_code] = useState("");
  const history = useHistory();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = {
      generate_code,
    };
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(token),
    };
    const response = await fetch(
      `${BASE_URL}/email/confirmotp`,
      requestOptions
    );
    const data = await response.json();
    sessionStorage.setItem("token", JSON.stringify(data));
    // console.log(data);
    if (data.success === true) {
      history.push("/dashboard");
    }
  };
  const navigate = useHistory();
  return (
    <div>
      {" "}
      <div className="m-auto">
        <div className="main-login-page">
          <div className="pageSwitcher btnSwitcher-pg">
            {/* <NavLink
              to="/"
              activeClassName="pageSwitcherItem-active"
              className="pageSwitcherItem"
            >
              OTP
            </NavLink> */}
            <button
              className="formFieldButton login-btn-form "
              onClick={() => navigate.goBack()}
            >
              Back
            </button>
          </div>

          <div className="formCenter">
            <form className="formFields" onSubmit={handleSubmit}>
              <div className="formField flex-column d-flex justify-content-start">
                <label className="formFieldLabel " htmlFor="email">
                  OTP
                </label>
                <input
                  type="text"
                  id="email"
                  className="formFieldInput"
                  placeholder="Enter your OTP"
                  value={generate_code}
                  size="lg"
                  onChange={(e) => setGenerate_code(e.target.value)}
                />
              </div>
              <div className="formField btn-field  ">
                <a href="/" className="formFieldButton login-btn-form">
                  Cancel
                </a>
                {/* <NavLink
                  to="/createnew"
                  className="formFieldButton login-btn-form "
                >
                  Confirm{" "}
                </NavLink> */}
                <button
                  type="submit"
                  className="formFieldButton login-btn-form "
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
        <div className="footer-login">
          <a href="https://react.opsways.com/" target="_blank ">
            <img src={Logo} alt="opsways" className="footoer-logo" />
          </a>
        </div>
      </div>
    </div>
  );
}
