import moment from "moment-timezone";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Preloader from "assets/images/preloader.gif";
import { Icon } from "@iconify/react";

import "./index.css";

export default function Invoices() {
  const [invoice, setInvoice] = useState([]);
  const [loading, setloading] = useState(undefined);
  const [completed, setcompleted] = useState(undefined);

  const BASE_URL = process.env.REACT_APP_API_BASE_URL;

  useEffect(() => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Bearer " + JSON.parse(sessionStorage.getItem("token")).data.token,
      },
    };
    fetch(
      `${BASE_URL}/invoice/fetch/${
        JSON.parse(sessionStorage.getItem("token")).data.clientuserid
      }`,
      requestOptions
    )
      .then((response) => response.json())
      .then((response) => {
        setInvoice(response.data.clientInvoice.invoices);

        setTimeout(() => {
          setcompleted(true);
        }, 1000);
        setloading(false);
        // console.log(setInvoice(response.data.clientInvoice));
        // console.log(response.data.clientInvoice);
      });
  }, []);

  const history = useHistory();
  // history.push("/")

  return (
    <>
      <div className="col-lg-12 stretch-card">
        <div className="card card-top">
          <div className="card-body">
            <h3 className="">
              <span className="menu-icon">
                <i
                  className="mdi mdi-library-books"
                  style={{ color: "#ddbc6b" }}
                ></i>{" "}
              </span>
              Recent Invoices
            </h3>
            <div className="table-responsive text-center">
              <table className="table table-hover">
                <thead className="table table-header">
                  <tr>
                    <th> Invoice No </th>
                    <th> Name </th>
                    <th> Due Date </th>
                    <th> Status </th>
                  </tr>
                </thead>
                {!completed ? (
                  <>
                    {!loading ? (
                      <div className="d-flex justify-content-md-center">
                        <div className="spinner text-center ">
                          {/* <img
                            src={Preloader}
                            alt="preloader"
                            className="img-lodder"
                          /> */}
                          <br />
                          <span className="span_title">
                            <h6>Loading </h6>
                          </span>
                          <span>
                            <Icon
                              icon="el:refresh"
                              color="#33a280"
                              width={20}
                              height={20}
                            />
                          </span>
                          <br />
                        </div>
                      </div>
                    ) : (
                      <div className="completed">&#x2713;</div>
                    )}
                  </>
                ) : (
                  <>
                    <tbody className="table-body">
                      {invoice.slice(0, 5).map((mess) =>
                        mess.invoice_status === "Paid" ? (
                          <tr className="table-success">
                            <td>{mess.invoice_no}</td>
                            <td>{mess.client_name}</td>
                            <td>{moment(mess.due_date).format("D-MM-YYYY")}</td>
                            <td>
                              {" "}
                              <label className="badge badge-success">
                                {mess.invoice_status}
                              </label>
                            </td>
                          </tr>
                        ) : (
                          <tr className="table-success">
                            <td>{mess.invoice_no}</td>
                            <td>{mess.client_name}</td>
                            <td>{moment(mess.due_date).format("D-MM-YYYY")}</td>
                            <td>
                              {" "}
                              <label className="badge badge-danger">
                                {mess.invoice_status}
                              </label>
                            </td>
                          </tr>
                        )
                      )}
                    </tbody>
                  </>
                )}
              </table>
              <div className="d-flex justify-content-end mt-3">
                <div
                  class="badge badge-primary"
                  style={{ cursor: "pointer" }}
                  onClick={() => history.push("/invoices/InvoiceTable")}
                >
                  Load more ...{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
