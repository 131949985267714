import React from "react";
import { Link } from "react-router-dom";
import { NavLink, useHistory } from "react-router-dom";
export default function Register() {
  const navigate = useHistory();
  return (
    <div className="m-auto">
      <div className="main-login-page">
        <div className="pageSwitcher  btnSwitcher-pg">
          {/* <NavLink
            to="/"
            activeClassName="pageSwitcherItem-active"
            className="pageSwitcherItem"
          >
            Sign In
          </NavLink> */}
          <button
            className="formFieldButton login-btn-form "
            onClick={() => navigate.goBack()}
          >
            Back
          </button>
          <NavLink
            exact
            to="/sign-up"
            activeClassName="pageSwitcherItem-active"
            className="pageSwitcherItem"
          >
            Sign Up
          </NavLink>
        </div>

        <div className="formTitle   title-signup">
          <NavLink
            to="/"
            activeClassName="formTitleLink-active"
            className="formTitleLink"
          >
            Sign In
          </NavLink>{" "}
          or{" "}
          <NavLink
            exact
            to="/sign-up"
            activeClassName="formTitleLink-active"
            className="formTitleLink"
          >
            Sign Up
          </NavLink>
        </div>

        <div className="formCenter">
          <form className="formFields">
            <div className="formField">
              <label className="formFieldLabel" htmlFor="name">
                Full Name
              </label>
              <input
                type="text"
                id="name"
                className="formFieldInput"
                placeholder="Enter your full name"
                name="name"
              />
            </div>
            <div className="formField">
              <label className="formFieldLabel" htmlFor="password">
                Password
              </label>
              <input
                type="password"
                id="password"
                className="formFieldInput"
                placeholder="Enter your password"
                name="password"
                maxLength={25}
              />
            </div>
            <div className="formField">
              <label className="formFieldLabel" htmlFor="email">
                E-Mail Address
              </label>
              <input
                type="email"
                id="email"
                className="formFieldInput"
                placeholder="Enter your email"
                name="email"
              />
            </div>

            <div className="formField">
              <label className="formFieldCheckboxLabel">
                <input
                  className="formFieldCheckbox"
                  type="checkbox"
                  name="hasAgreed"
                />{" "}
                I agree all statements in{" "}
                <a href="null" className="formFieldTermsLink">
                  terms of service
                </a>
              </label>
            </div>

            <div className="formField btn-field  ">
              <button className="formFieldButton login-btn-form ">
                Sign Up
              </button>{" "}
              <Link to="/sign-in" className="formFieldLink">
                I'm already member
              </Link>
            </div>
          </form>
          <div className="d-flex justify-content-center  d-flex align-items-end  ">
            <a href="https://static.opsways.com/">
              <p className="logo-footer  mt-5  ">Powered by OpsWays</p>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
