import React from "react";

export default function Footer() {
  const today = new Date();
  const year = today.getFullYear();
  return (
    <div>
      {" "}
      <footer className="footer ">
        <div className="container-fluid">
          <div className="d-sm-flex justify-content-center justify-content-sm-between py-2 w-100">
            <span className="text-muted text-center text-sm-left d-block d-sm-inline-block footer-text">
              Copyright © 
              <a
                href="/"
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: "#ddbc6b", fontWeight: "500" }}
              >
                healway.com{" "}
              </a>{" "}
              {year}
            </span>
          </div>
        </div>
      </footer>
    </div>
  );
}
