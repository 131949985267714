import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { Collapse } from "react-bootstrap";
import Logo from "assets/Logo.png";
// import miniLogo from "assets/images/logo-mini.svg";
// import SecurityAssesmentModal from "components/Modal/SecurityAssesmentModal";
// import { ScrollTo } from "react-scroll-to";

// import Default from "assets/images/faces/avatar.png";

class Sidebar extends Component {
  state = {};

  toggleMenuState(menuState) {
    if (this.state[menuState]) {
      this.setState({ [menuState]: false });
    } else if (Object.keys(this.state).length === 0) {
      this.setState({ [menuState]: true });
    } else {
      Object.keys(this.state).forEach((i) => {
        this.setState({ [i]: false });
      });
      this.setState({ [menuState]: true });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }

  onRouteChanged() {
    document.querySelector("#sidebar").classList.remove("active");
    Object.keys(this.state).forEach((i) => {
      this.setState({ [i]: false });
    });

    const dropdownPaths = [
      { path: "/apps", state: "appsMenuOpen" },
      { path: "/pages", state: "pagesMenuOpen" },
      { path: "/operations", state: "operationsMenuOpen" },
      { path: "/services", state: "servicesMenuOpen" },
      { path: "/db-engine", state: "DBEngineMenuOpen" },
      { path: "/invoices", state: "invoicesMenuOpen" },
      { path: "/addons", state: "addonsMenuOpen" },
      // { path: "/teams", state: "teamsMenuOpen" },
      { path: "/tickets", state: "supportMenuOpen" },
      { path: "/settings", state: "settingsMenuOpen" },
      { path: "/terms", state: "reportMenuOpen" },
      { path: "/subscription", state: "subscriptionMenuOpen" },
      // { path: "/quote", state: "supportMenuOpen" },
      { path: "/howtos", state: "supportMenuOpen" },
      { path: "/frequentlyaskequestion", state: "communityMenuOpen" },
      { path: "/referafriend", state: "referafriendMenuOpen" },
      { path: "/terms", state: "communityMenuOpen" },
    ];

    dropdownPaths.forEach((obj) => {
      if (this.isPathActive(obj.path)) {
        this.setState({ [obj.state]: true });
      }
    });
  }

  render() {
    return (
      <nav className="sidebar sidebar-offcanvas " id="sidebar">
        <div className="sidebar-brand-wrapper d-none d-lg-flex align-items-center justify-content-center fixed-top ">
          <a className="sidebar-brand brand-logo" href="/Dashboard">
            <img src={Logo} alt="logo" />
          </a>
          <a className="sidebar-brand brand-logo-mini " href="/Dashboard">
            <img src={Logo} alt="logo" />
          </a>
        </div>

        <ul className="nav sidebar-fixed sidebar-hoverscroll">
          <li className="nav-item profile">
            {/* <div className="profile-desc">
              <div className="profile-pic">
                <div className="count-indicator">
                  <img
                    className="img-xs rounded-circle "
                    src={Default}
                    alt="profile"
                  />
                  <span className="count bg-success"></span>
                </div>
                <div className="profile-name">
                  <h5
                    className="mb-0 font-weight-normal"
                    style={{ color: "white" }}
                  >
                    {
                      JSON.parse(sessionStorage.getItem("token")).data
                        .client_name
                    }
                  </h5>
                  <span>Master</span>
                </div>
              </div>
            </div> */}
          </li>

          <li
            className={
              this.isPathActive("/dashboard")
                ? "nav-item menu-items active"
                : "nav-item menu-items"
            }
          >
            <Link className="nav-link" to="/dashboard">
              <span className="menu-icon">
                <i className="mdi mdi-speedometer"></i>
              </span>
              <span className="menu-title">Dashboard</span>
            </Link>
          </li>

          <li
            className={
              this.isPathActive("/subscription")
                ? "nav-item menu-items active"
                : "nav-item menu-items"
            }
          >
            <div
              className={
                this.state.subscriptionMenuOpen
                  ? "nav-link menu-expanded"
                  : "nav-link"
              }
              onClick={() => this.toggleMenuState("subscriptionMenuOpen")}
              data-toggle="collapse"
            >
              <span className="menu-icon">
                <i className="mdi mdi-shopping-music"></i>
              </span>
              <span className="menu-title">Subscriptions</span>
              <i className="menu-arrow"></i>
            </div>
            <Collapse in={this.state.subscriptionMenuOpen}>
              <div>
                <ul className="nav flex-column sub-menu">
                  <li className="nav-item">
                    {" "}
                    <Link
                      className={
                        this.isPathActive("/subscription/subscription")
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to="/subscription/subscription"
                    >
                      Active
                    </Link>
                  </li>
                  <li className="nav-item">
                    {" "}
                    <Link
                      className={
                        this.isPathActive("/subscription/cancelsubscription")
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to="/subscription/cancelsubscription"
                    >
                      In-Active
                    </Link>
                  </li>
                </ul>
              </div>
            </Collapse>
          </li>
          <li
            className={
              this.isPathActive("/teams")
                ? "nav-item menu-items active"
                : "nav-item menu-items"
            }
          >
            <div
              className={
                this.state.teamsMenuOpen ? "nav-link menu-expanded" : "nav-link"
              }
              onClick={() => this.toggleMenuState("teamsMenuOpen")}
              data-toggle="collapse"
            >
              <span className="menu-icon">
                <i className="mdi mdi-account-multiple"></i>
              </span>
              <span className="menu-title">Offers</span>
              <i className="menu-arrow"></i>
            </div>
            <Collapse in={this.state.teamsMenuOpen}>
              <div>
                <ul className="nav flex-column sub-menu">
                  <li className="nav-item">
                    {" "}
                    <Link
                      className={
                        this.isPathActive("/offers/offers")
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to="/offers/offers"
                    >
                      Offers
                    </Link>
                  </li>
                  {/* <li className="nav-item">
                    {" "}
                    <Link
                      className={
                        this.isPathActive("/teams/teams")
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to="/teams/teams"
                    >
                      Activity
                    </Link>
                  </li> */}
                </ul>
              </div>
            </Collapse>
          </li>

          <li
            className={
              this.isPathActive("/invoices")
                ? "nav-item menu-items active"
                : "nav-item menu-items"
            }
          >
            <div
              className={
                this.state.invoicesMenuOpen
                  ? "nav-link menu-expanded"
                  : "nav-link"
              }
              onClick={() => this.toggleMenuState("invoicesMenuOpen")}
              data-toggle="collapse"
            >
              <span className="menu-icon ">
                <i className="mdi  mdi-currency-btc"></i>
              </span>
              <span className="menu-title">Billing</span>
              <i className="menu-arrow"></i>
            </div>
            <Collapse in={this.state.invoicesMenuOpen}>
              <div>
                <ul className="nav flex-column sub-menu">
                  <li className="nav-item">
                    {" "}
                    <Link
                      className={
                        this.isPathActive("/invoices/InvoiceTable")
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to="/invoices/InvoiceTable"
                    >
                      Invoices
                    </Link>
                  </li>
                </ul>
              </div>
            </Collapse>
          </li>
          <li
            className={
              this.isPathActive("/terms")
                ? "nav-item menu-items active"
                : "nav-item menu-items"
            }
          >
            <div
              className={
                this.state.reportMenuOpen
                  ? "nav-link menu-expanded"
                  : "nav-link"
              }
              onClick={() => this.toggleMenuState("reportMenuOpen")}
              data-toggle="collapse"
            >
              <span className="menu-icon">
                <i className="mdi mdi-library-books"></i>
              </span>
              <span className="menu-title">Terms & Conditions</span>
              <i className="menu-arrow"></i>
            </div>
            <Collapse in={this.state.reportMenuOpen}>
              <div>
                <ul className="nav flex-column sub-menu">
                  <li className="nav-item">
                    {" "}
                    <Link
                      className={
                        this.isPathActive("/terms/terms")
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to="/terms/terms"
                    >
                      Terms & Conditions
                    </Link>
                  </li>
                  <li className="nav-item">
                    {" "}
                    <Link
                      className={
                        this.isPathActive("/terms/privacy")
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to="/terms/privacy"
                    >
                      Privacy Policy
                    </Link>
                  </li>
                </ul>
              </div>
            </Collapse>
          </li>
          <li
            className={
              this.isPathActive("/settings")
                ? "nav-item menu-items active"
                : "nav-item menu-items"
            }
          >
            <div
              className={
                this.state.settingsMenuOpen
                  ? "nav-link menu-expanded"
                  : "nav-link"
              }
              onClick={() => this.toggleMenuState("settingsMenuOpen")}
              data-toggle="collapse"
            >
              <span className="menu-icon">
                <i className="mdi mdi-spin mdi-settings"></i>
              </span>
              <span className="menu-title">Profile</span>
              <i className="menu-arrow"></i>
            </div>
            <Collapse in={this.state.settingsMenuOpen}>
              <div>
                <ul className="nav flex-column sub-menu">
                  <li className="nav-item">
                    {" "}
                    <Link
                      className={
                        this.isPathActive("/settings/settings")
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to="/settings/settings"
                    >
                      Settings
                    </Link>
                  </li>
                  <li className="nav-item">
                    {" "}
                    <Link
                      className={
                        this.isPathActive("/settings/bio")
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to="/settings/bio"
                    >
                      Bio
                    </Link>
                  </li>
                  <li className="nav-item">
                    {" "}
                    <Link
                      className={
                        this.isPathActive("/settings/preview")
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to="/settings/preview"
                    >
                      Preview Profile
                    </Link>
                  </li>
                </ul>
              </div>
            </Collapse>
          </li>

       

          {/* <li className="nav-item menu-items">
            <div className="nav-link" rel="noopener noreferrer">
              <span className="menu-icon">
                <i className="mdi mdi-share-variant"></i>
              </span>
              <span className="menu-title">
                <ReferModal />
              </span>
            </div>
          </li> */}
        </ul>
      </nav>
    );
  }

  isPathActive(path) {
    return this.props.location.pathname.startsWith(path);
  }

  componentDidMount() {
    this.onRouteChanged();
    const body = document.querySelector("body");
    document.querySelectorAll(".sidebar .nav-item").forEach((el) => {
      el.addEventListener("mouseover", function () {
        if (body.classList.contains("sidebar-icon-only")) {
          el.classList.add("hover-open");
        }
      });
      el.addEventListener("mouseout", function () {
        if (body.classList.contains("sidebar-icon-only")) {
          el.classList.remove("hover-open");
        }
      });
    });
  }
}

export default withRouter(Sidebar);
