import React, { useEffect, useRef } from "react";
import "video-react/dist/video-react.css";
import Video from "assets/images/background-vid.mp4";

export default function Background() {
  const videoEl = useRef(null);

  const attemptPlay = () => {
    videoEl &&
      videoEl.current &&
      videoEl.current.play().catch((error) => {
        console.error("Error attempting to play", error);
      });
  };

  useEffect(() => {
    attemptPlay();
  }, []);

  return (
    <div className="video-play videotag">
      {/* <Player src={Video} autoPlay={true} /> */}
      <video
        playsInline
        loop
        muted
        autoPlay={true}
        alt="All the devices"
        src={Video}
        ref={videoEl}
      />
    </div>
  );
}
