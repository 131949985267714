import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
// import miniLogo from "assets/images/logo-mini.svg";
// import AddNewDropdown from "components/AddNewDropdown";
// import Default from "assets/images/faces/avatar.png";
// import moment from "moment-timezone";
import "./index.css";
// import { Icon } from "@iconify/react";
// import Preloader from "assets/images/preloader.gif";
function toggleOffcanvas() {
  document.querySelector(".sidebar-offcanvas").classList.toggle("active");
}

export default function Navbar() {
  const [ticket, setTicket] = useState([]);
  const [loading, setloading] = useState(undefined);
  const [completed, setcompleted] = useState(undefined);
  const [notification, setnotification] = useState([]);

  const [notificationCount, setNotificationCount] = useState(
    sessionStorage.getItem("notificationCount") || 3
  );

  useEffect(() => {
    sessionStorage.setItem("notificationCount", notificationCount);
  }, [notificationCount]);

  const handleNotificationClick = () => {
    if (notificationCount > 0) {
      setNotificationCount(notificationCount - 3);
    }
  };

  const [ticketCount, setticketCount] = useState(
    sessionStorage.getItem("ticketCount") || 3
  );

  useEffect(() => {
    sessionStorage.setItem("ticketCount", ticketCount);
  }, [ticketCount]);

  const handleTicketClick = () => {
    if (ticketCount > 0) {
      setticketCount(ticketCount - 3);
    }
  };

  const [isOpen, setIsOpen] = useState(false);
  const [image, setImage] = useState([]);
  const handleOnClick = () => {
    setIsOpen(false);
  };
  const BASE_URL = process.env.REACT_APP_API_BASE_URL;

  // const BASE_URL_ZENDESK = process.env.REACT_APP_ZENDESK_BASE_URL;
  // const getTicket = () => {
  //   setTimeout(() => {
  //     const data = {
  //       email: JSON.parse(sessionStorage.getItem("token")).data.email,
  //     };
  //     const requestOptions = {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization:
  //           "Bearer " + JSON.parse(sessionStorage.getItem("token")).data.token,
  //       },
  //       body: JSON.stringify(data),
  //     };
  //     fetch(`${BASE_URL_ZENDESK}allmail`, requestOptions)
  //       .then((response) => response.json())
  //       .then((response) => {
  //         setTicket(response);
  //         setloading(true);
  //         setTimeout(() => {
  //           setcompleted(true);
  //         }, 1000);
  //       });
  //   }, 2000);
  // };

  const getimage = () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Bearer " + JSON.parse(sessionStorage.getItem("token")).data.token,
      },
    };
    fetch(
      `${BASE_URL}/client/listclientsetting/${
        JSON.parse(sessionStorage.getItem("token")).data.clientuserid
      }`,
      requestOptions
    )
      .then((response) => response.json())
      .then((response) => {
        setImage(response.data.listClientUsersSetting);
        // console.log(response.data.listClientUsersSetting);
      });
  };

  const getnotify = () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Bearer " + JSON.parse(sessionStorage.getItem("token")).data.token,
      },
    };
    fetch(
      `${BASE_URL}/notification/client/${
        JSON.parse(sessionStorage.getItem("token")).data.clientuserid
      }`,
      requestOptions
    )
      .then((response) => response.json())
      .then((response) => {
        setnotification(response.data.notification);
        setTimeout(() => {
          setcompleted(true);
        }, 1000);
        // console.log(response.data.notification);
      });
  };

  const logtime = () => {
    const data = {
      loginid: JSON.parse(sessionStorage.getItem("logtime")),
      logout_time: new Date(),
    };

    const requestOptions = {
      method: "PUT",

      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Bearer " + JSON.parse(sessionStorage.getItem("token")).data.token,
      },
      body: JSON.stringify(data),
    };
    fetch(`${BASE_URL}/log/edit`, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        // console.log(response);

        sessionStorage.clear();
        localStorage.clear();
        window.location = "/";
      });
  };
  useEffect(() => {
    // getTicket();
    getimage();
    getnotify();
  }, []);

  return (
    <div>
      {" "}
      <nav className="navbar p-0 fixed-top d-flex flex-row ">
        {/* <div className="navbar-brand-wrapper d-flex d-lg-none align-items-center justify-content-center">
          <Link className="navbar-brand brand-logo-mini" to="/">
            <img src={miniLogo} alt="logo" />
          </Link>
        </div> */}
        <div className="navbar-menu-wrapper flex-grow d-flex align-items-stretch">
          <button
            className="navbar-toggler align-self-center"
            type="button"
            onClick={() => document.body.classList.toggle("sidebar-icon-only")}
          >
            <span className="mdi mdi-menu" style={{ fontSize: "20px" }}></span>
          </button>
          <ul className="navbar-nav w-100">
            <li className="nav-item w-100"></li>
          </ul>
          <ul className="navbar-nav navbar-nav-right ">
            {/* <AddNewDropdown /> */}

            {/* <li className="nav-item d-none d-lg-block">
              <a
                className="nav-link"
                href="!#"
                onClick={(event) => event.preventDefault()}
              >
                <Link to="/howtos/howtos">
                  <i
                    className="mdi mdi-help-box"
                    style={{ color: "black" }}
                  ></i>
                </Link>
              </a>
            </li> */}
            {/* <Dropdown alignRight as="li" className="nav-item border-left ">
              <Dropdown.Toggle
                as="a"
                className="nav-link count-indicator cursor-pointer"
              >
                <i
                  className="mdi mdi-ticket"
                  style={{ color: "black" }}
                  onClick={handleTicketClick}
                ></i>
                {ticketCount > 0 && (
                  <span
                    style={{ height: "100", width: "100" }}
                    className={ticketCount > 0 ? "count bg-success" : ""}
                  >
                    {" "}
                    {ticketCount}{" "}
                  </span>
                )}
              </Dropdown.Toggle>

              <Dropdown.Menu className="navbar-dropdown preview-list margin-responsives margin-responsiveness">
                <h6 className="p-3 mb-0">
                  <>Tickets</>
                </h6>
                <Dropdown.Divider />
                <p className="p-3 mb-0 text-center">
                  <Link to="/tickets/tickets">
                    <>All Tickets</>
                  </Link>
                </p>
              </Dropdown.Menu>
            </Dropdown> */}
            {/* <Dropdown alignRight as="li" className="nav-item border-left">
              <Dropdown.Toggle
                as="a"
                className="nav-link count-indicator cursor-pointer"
              >
                <i
                  className="mdi mdi-bell"
                  onClick={handleNotificationClick}
                ></i>

                {notificationCount > 0 && (
                  <span
                    style={{ height: "100", width: "100" }}
                    className={notificationCount > 0 ? "count bg-danger" : ""}
                  >
                    {" "}
                    {notificationCount}{" "}
                  </span>
                )}
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdown-menu navbar-dropdown preview-list margin-responsive margin-responsiveness">
                <h6 className="p-3 mb-0 ">
                  <>Notifications</>
                </h6>
                <Dropdown.Divider />
                {!completed ? (
                  <>
                    {!loading ? (
                      <div className="d-flex justify-content-md-center">
                        <div className="spinner text-center ">
                          <img
                            src={Preloader}
                            alt="preloader"
                            className="img-lodder"
                          />
                          <br />
                          <span className="span_title">
                            <h6>Loading </h6>
                          </span>
                          <span>
                            <Icon
                              icon="el:refresh"
                              color="#33a280"
                              width={20}
                              height={20}
                            />
                          </span>
                          <br />
                        </div>
                      </div>
                    ) : (
                      <div className="completed">&#x2713;</div>
                    )}
                  </>
                ) : (
                  <>
                    {notification.slice(0, 3).map((mess) => (
                      <Dropdown.Item
                        className="dropdown-item preview-item"
                        onClick={(evt) => evt.preventDefault()}
                      >
                        <div className="preview-thumbnail">
                          <div>
                            <i className="mdi mdi-calendar text-success"></i>
                          </div>
                        </div>
                        <div className="preview-item-content">
                          <p className=" mb-1">
                            <>{mess.subject}</>
                          </p>
                          <p className="text-muted ellipsis mb-0">
                            <>{mess.description}</>
                          </p>
                        </div>
                      </Dropdown.Item>
                    ))}
                  </>
                )}

                <Dropdown.Divider />

                <Dropdown.Item
                  onClick={handleOnClick}
                  className=" dropdown-close"
                >
                  <p className="p-3 mb-0 text-center">
                    <Link to="/notification">See all Notification </Link>
                  </p>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown> */}
            <Dropdown alignRight as="li" className="nav-item">
              <Dropdown.Toggle
                as="a"
                className="nav-link cursor-pointer no-caret"
              >
                {image.map((meg) => (
                  <div className="navbar-profile">
                    <img
                      className=" img-xs rounded-circle "
                      src={require(`assets/images/faces/${meg.imageurl}`)}
                      alt="clinr_img"
                    />
                    <p className="mb-0 d-none d-sm-block navbar-profile-name">
                      {meg.client_name}
                    </p>
                    <i className="mdi mdi-menu-down d-none d-sm-block"></i>
                  </div>
                ))}
              </Dropdown.Toggle>

              <Dropdown.Menu className="navbar-dropdown preview-list navbar-profile-dropdown-menu">
                <h6 className="p-3 mb-0">
                  <>Profile</>
                </h6>
                <Dropdown.Divider />
                <Dropdown.Item
                  href="!#"
                  onClick={(evt) => evt.preventDefault()}
                  className="preview-item"
                >
                  <div className="preview-thumbnail">
                    <div className="">
                      <i
                        className="mdi mdi-spin mdi-settings"
                        style={{ color: "#ddbc6b" }}
                      ></i>
                    </div>
                  </div>
                  <div className="preview-item-content">
                    <Link className=" btn-lite mb-1" to="/settings">
                      <>Settings</>
                    </Link>
                  </div>
                </Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item
                  href="!#"
                  onClick={(evt) => evt.preventDefault()}
                  className="preview-item"
                >
                  <div className="preview-thumbnail">
                    <div>
                      <i
                        className="mdi mdi-logout "
                        style={{ color: "red" }}
                      ></i>
                    </div>
                  </div>
                  <div className="preview-item-content">
                    <p
                      className="btn-lite mb-1"
                      onClick={() => {
                        logtime();
                      }}
                    >
                      Logout
                    </p>
                  </div>
                </Dropdown.Item>
                <Dropdown.Divider />
              </Dropdown.Menu>
            </Dropdown>
          </ul>
          <button
            className="navbar-toggler navbar-toggler-right d-lg-none align-self-center"
            type="button"
            onClick={() => {
              toggleOffcanvas();
            }}
          >
            <span className="mdi mdi-format-line-spacing"></span>
          </button>
        </div>
      </nav>
    </div>
  );
}
