import ReactPaginate from "react-paginate";
import moment from "moment-timezone";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./index.css";
import Preloader from "assets/images/preloader.gif";
import { Icon } from "@iconify/react";

export default function InvoiceTable() {
  const [invoice, setInvoice] = useState([]);
  // const [reserved, setReserved] = useState([]);
  const [total, setTotal] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const [loading, setloading] = useState(undefined);
  const [completed, setcompleted] = useState(undefined);
  const usersPerPage = 10;
  const pagesVisited = pageNumber * usersPerPage;
  const pageCount = Math.ceil(invoice.length / usersPerPage);

  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };
  const BASE_URL = process.env.REACT_APP_API_BASE_URL;
  const Api = () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Bearer " + JSON.parse(sessionStorage.getItem("token")).data.token,
      },
    };
    fetch(
      `${BASE_URL}/invoice/fetch/${
        JSON.parse(sessionStorage.getItem("token")).data.clientuserid
      }`,
      requestOptions
    )
      .then((response) => response.json())
      .then((response) => {
        setInvoice(response.data.clientInvoice.invoices);
        // setReserved(response.data.clientInvoice.invoices[0].reserved_amount);
        setTotal(response.data.clientInvoice.total);
        setTimeout(() => {
          setcompleted(true);
        }, 1000);
          console.log(response.data.clientInvoice);
        setloading(false);
      });
  };
  useEffect(() => {
    Api();
  }, []);

  return (
    <div>
      <div className="col-lg-12 grid-margin stretch-card">
        <div className="card">
          <div className="card-body">
            <h4 className="card-title">Invoices</h4>
            <p className="card-description reserved-text myDIV">
            </p>
            <div className="hide">
            </div>
            <div className="table-responsive text-center">
              <table className="table table-hover">
                <thead className="table table-header">
                  <tr>
                    <th>Invoice No</th>
                    <th>Created Date</th>
                    <th>Due Date</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                {!completed ? (
                  <>
                    {!loading ? (
                      <div className="d-flex justify-content-md-center">
                        <div className="spinner text-center ">
                          {/* <img
                            src={Preloader}
                            alt="preloader"
                            className="img-lodder"
                          /> */}
                          <br />
                          <span className="span_title">
                            <h6>Loading </h6>
                          </span>
                          <span>
                            <Icon
                              icon="el:refresh"
                              color="#33a280"
                              width={20}
                              height={20}
                            />
                          </span>
                          <br />
                        </div>
                      </div>
                    ) : (
                      <div className="completed">&#x2713;</div>
                    )}
                  </>
                ) : (
                  <tbody className="table-body">
                    {invoice
                      .slice(pagesVisited, pagesVisited + usersPerPage)
                      .map((mess) =>
                        mess.invoice_status === "Paid" ? (
                          <tr className="table-success">
                            <td>{mess.invoice_no}</td>
                            <td>
                              {moment(mess.created_at).format("D-MM-YYYY")}
                            </td>
                            <td>{moment(mess.due_date).format("D-MM-YYYY")}</td>
                            <td>
                              {" "}
                              <label className="badge badge-success">
                                {mess.invoice_status}
                              </label>
                            </td>
                            <td>
                              <Link
                                to={{
                                  pathname: `/viewinvoice/${mess.order_id}/${mess.invoice_id}`,
                                  // state: { data: reserved },
                                }}
                                className="badge badge-info"
                              >
                                View
                              </Link>
                            </td>
                          </tr>
                        ) : (
                          <tr className="table-success">
                            <td>{mess.invoice_no}</td>
                            <td>
                              {moment(mess.created_at).format("D-MM-YYYY")}
                            </td>
                            <td>{moment(mess.due_date).format("D-MM-YYYY")}</td>
                            <td>
                              {" "}
                              <label className="badge badge-danger">
                                {mess.invoice_status}
                              </label>
                            </td>
                            <td>
                              <Link
                                to={{
                                  pathname: `/viewinvoice/${mess.order_id}/${mess.invoice_id}`,
                                  // state: { data: reserved },
                                }}
                                className="badge badge-info"
                              >
                                View
                              </Link>
                            </td>
                          </tr>
                        )
                      )}
                  </tbody>
                )}
              </table>
            </div>
            <div className="mt-5">
              <ReactPaginate
                previousLabel={"<<"}
                nextLabel={">>"}
                breakLabel={"..."}
                pageCount={pageCount}
                onPageChange={changePage}
                containerClassName={"pagination justify-content-center"}
                pageClassName={"page-item"}
                pageLinkClassName={"page-link"}
                previousClassName={"page-item"}
                previousLinkClassName={"page-link"}
                nextClassName={"page-item"}
                nextLinkClassName={"page-link"}
                breakClassName={"page-item"}
                breakLinkClassName={"page-link"}
                activeClassName={"active"}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
