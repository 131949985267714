import React from "react";
import { useHistory } from "react-router-dom";

export default function ReviewsLayout() {
  const history = useHistory();

  return (
    <>
      <div className="col-xl-12 stretch-card">
        <div className="card card-top">
          <div className="card-body">
            <h3>
              <span className="menu-icon">
                <i
                  className="mdi mdi-wunderlist"
                  style={{ color: "#ddbc6b" }}
                ></i>{" "}
              </span>{" "}
              Reviews
            </h3>
            <div className="table-responsive text-center">
              <table className="table table-hover" style={{ overflow: "auto" }}>
                <thead className="table table-header">
                  <tr>
                    <th> Client Name </th>
                    <th> Review </th>
                    <th> Stars </th>
                  </tr>
                </thead>
                <tbody className="table-body">
                  <tr className="table-success">
                    <td>Jodie The Hamptons, NY</td>
                    <td>
                      ❝ Amazing! Some of the best massages I have ever had.
                      Definitely recommend as a fun alternative to going to a
                      spa. ❞{" "}
                    </td>
                    <td>
                      <i
                        className="mdi mdi-star-outline"
                        aria-hidden="true"
                      ></i>
                      <i
                        className="mdi mdi-star-outline"
                        aria-hidden="true"
                      ></i>
                      <i
                        className="mdi mdi-star-outline"
                        aria-hidden="true"
                      ></i>
                      <i
                        className="mdi mdi-star-outline"
                        aria-hidden="true"
                      ></i>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div className="d-flex justify-content-end mt-3">
                <div
                  class="badge badge-primary"
                  style={{ cursor: "pointer" }}
                  onClick={() => history.push("/reviews")}
                >
                  Load more ...{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
