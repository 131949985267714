import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import Logo from "assets/images/logo.svg";
import "../Login/Login.css";
import { Button } from "react-bootstrap";
import PasswordChecklist from "react-password-checklist";
import { Icon } from "@iconify/react";
export default function Confirmation() {
  const BASE_URL = process.env.REACT_APP_API_BASE_URL;
  const [generate_code, setGenerate_code] = useState("");
  const [active, setActive] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordAgain, setPasswordAgain] = useState("");
  const [showPassword, setShowPassword] = useState("");
  const navigate = useHistory();
  const handleSubmitCreate = async (e) => {
    e.preventDefault();
    const token = {
      generate_code,
      password,
    };
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(token),
    };
    const response = await fetch(
      `${BASE_URL}/email/createnewpassword`,
      requestOptions
    );
    const data = await response.json();
    sessionStorage.setItem("token", JSON.stringify(data));
    // console.log(data);
    // console.log(data.data.token);
    if (data.success === true) {
      window.location = "/user-page/login";
    } else {
      alert("Something Went Wrong");
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = {
      generate_code,
    };
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(token),
    };
    const response = await fetch(
      `${BASE_URL}/email/confirmotp`,
      requestOptions
    );
    const data = await response.json();
    sessionStorage.setItem("token", JSON.stringify(data));
    // console.log(data);
  };
  const togglePassword = (e) => {
    if (showPassword) {
      setShowPassword(false);
    } else {
      setShowPassword(true);
    }
  };
  const nextpage =()=>{
   navigate.push("/")
  }
  return (
    <div>
      {" "}
      <div className="m-auto">
        <div className="main-login-page">
          <div className="pageSwitcher  btnSwitcher-pg">
            <button
              className="formFieldButton login-btn-form "
              onClick={() => navigate.goBack()}
            >
              Back
            </button>
          </div>

          <div className="formCenter">
            <form className="formFields" onSubmit={handleSubmit}>
              <div className="formField flex-column d-flex justify-content-start">
                <label className="formFieldLabel " htmlFor="email">
                  OTP
                </label>
                <input
                  type="text"
                  id="email"
                  className="formFieldInput"
                  placeholder="Enter your OTP"
                  value={generate_code}
                  size="lg"
                  onChange={(e) => setGenerate_code(e.target.value)}
                />
              </div>
              <div className="formField btn-field  ">
                <Button
                  onClick={() => setActive(!active)}
                  className="formFieldButton login-btn-form "
                >
                  Forget Password{" "}
                </Button>
              </div>
            </form>
          </div>
          {active ? (
            <>
              <div className="container">
                <div className="row">
                  <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 ">
                    <form className="formFields" onSubmit={handleSubmitCreate}>
                      <div className="formField">
                        <label className="formFieldLabel" htmlFor="password">
                          Password
                        </label>

                        <div className="d-inline-flex password-input-width  ">
                          <input
                            type={showPassword ? "text" : "password"}
                            className="formFieldInput  "
                            name="password password-input-width"
                            id="password"
                            value={password}
                            placeholder="Password"
                            onChange={(e) => setPassword(e.target.value)}
                            maxLength={25}
                          />

                          <button
                            type="button"
                            className="btn btn-primary btn-sm password-eye-btn"
                            onClick={(e) => togglePassword(e)}
                          >
                            <Icon
                              className="icon-button"
                              icon="typcn:eye"
                              color="black"
                              onClick={(e) => togglePassword(e)}
                            />
                          </button>
                        </div>
                      </div>

                      <div className="formField">
                        <label className="formFieldLabel" htmlFor="password">
                          Confirm Password
                        </label>

                        <div className="d-inline-flex password-input-width  ">
                          <input
                            type={showPassword ? "text" : "password"}
                            className="formFieldInput  "
                            name="password password-input-width"
                            id="confirm password"
                            placeholder="confirm password"
                            onChange={(e) => setPasswordAgain(e.target.value)}
                          />
                          <button
                            type="button"
                            className="btn btn-primary btn-sm password-eye-btn"
                            onClick={(e) => togglePassword(e)}
                          >
                            <Icon
                              className="icon-button"
                              icon="typcn:eye"
                              color="black"
                              onClick={(e) => togglePassword(e)}
                            />
                          </button>
                        </div>
                        <div className="mt-3">
                          <PasswordChecklist
                            rules={[
                              "minLength",
                              "specialChar",
                              "number",
                              "capital",
                              "match",
                            ]}
                            minLength={8}
                            value={password}
                            valueAgain={passwordAgain}
                            onChange={(isValid) => {}}
                          />
                        </div>
                      </div>

                      {/* <div className="row formField btn-field d-flex d-flex jsutify-content-center ">
                        <div classNmae="col-lg-6 col-md-6 col-sm-12 col-xs-12 m-4">
                          <a
                            href="/"
                            className="formFieldButton login-btn-form"
                          >
                            Cancel
                          </a>
                        </div>
                        <br />
                        <div classNmae="col-lg-6 col-md-6 col-sm-12 col-xs-12 m-4">
                          <Button className="formFieldButton login-btn-form ">
                            Create New Password
                          </Button>
                        </div>
                      </div> */}

                      <div class="d-flex flex-column mb-3 mt-4">
                        <a></a>
                        <button
                          className="formFieldButton login-btn-form  login-btn-css"
                          onClick={nextpage}
                        >
                          Cancel
                        </button>{" "}
                        <button
                          className="formFieldButton login-btn-form login-btn-css text-center "
                        >
                          Create New Password
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </>
          ) : null}
        </div>
        <div className="footer-login">
          <a href="https://react.opsways.com/" target="_blank ">
            <img src={Logo} alt="opsways" className="footoer-logo" />
          </a>
        </div>
      </div>
    </div>
  );
}
