import { Icon } from "@iconify/react";
import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import Logo from "assets/images/logo.svg";
import PasswordChecklist from "react-password-checklist";
import { useHistory } from "react-router-dom";
export default function CreateNewPassword() {
  const navigate = useHistory();
  const [email, setEmail] = useState("");
  const BASE_URL = process.env.REACT_APP_API_BASE_URL;
  const [password, setPassword] = useState("");
  const [passwordAgain, setPasswordAgain] = useState("");
  const [showPassword, setShowPassword] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = {
      email,
      password,
    };
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(token),
    };
    const response = await fetch(
      `${BASE_URL}/email/createnewpassword`,
      requestOptions
    );
    const data = await response.json();
    sessionStorage.setItem("token", JSON.stringify(data));
    // console.log(data);
    // console.log(data.data.token);
    if (data.success === true) {
      window.location = "/user-page/login";
    } else {
      alert("Something Went Wrong");
    }
  };
  const togglePassword = (e) => {
    if (showPassword) {
      setShowPassword(false);
    } else {
      setShowPassword(true);
    }
  };
  return (
    <div className="m-auto">
      <div className="main-login-page">
        <div className="pageSwitcher  btnSwitcher-pg">
          {/* <NavLink to="/"> */}
          <button
            className="formFieldButton login-btn-form "
            onClick={() => navigate.goBack()}
          >
            Back
          </button>
          {/* </NavLink> */}
        </div>

        <div className="formCenter">
          <form className="formFields" onSubmit={handleSubmit}>
            <div className="formField flex-column d-flex justify-content-start">
              <label className="formFieldLabel " htmlFor="email">
                Confirm E-Mail Address
              </label>
              <input
                type="email"
                id="email"
                className="formFieldInput"
                placeholder="Enter your email"
                value={email}
                size="lg"
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>

            {/* <div className="formField flex-column d-flex justify-content-start">
              <label className="formFieldLabel " htmlFor="email">
               Confirm E-Mail Address
              </label>
              <input
                type="email"
                id="email"
                className="formFieldInput"
                placeholder="Enter your email"
                value={email}
                size="lg"
                onChange={(e) => setEmail(e.target.value)}
              />
            </div> */}

            <div className="formField">
              <label className="formFieldLabel" htmlFor="password">
                Password
              </label>

              <div className="d-inline-flex password-input-width  ">
                <input
                  type={showPassword ? "text" : "password"}
                  className="formFieldInput  "
                  name="password password-input-width"
                  id="password"
                  value={password}
                  placeholder="Password"
                  onChange={(e) => setPassword(e.target.value)}
                  maxLength={25}
                />

                <button
                  type="button"
                  className="btn btn-primary btn-sm password-eye-btn"
                  onClick={(e) => togglePassword(e)}
                >
                  <Icon
                    className="icon-button"
                    icon="typcn:eye"
                    color="black"
                    //onClick={(e) => togglePassword(e)}
                  />
                </button>
              </div>
              {/* <div className="mt-1 d-flex justify-content-end">
                <NavLink to="/resetpassword" className="forget-password-link ">
                  Forget password?{" "}
                </NavLink>
              </div> */}
            </div>

            {/* <div className="formField">
              <label className="formFieldLabel" htmlFor="password">
                Password
              </label>

              <div className="d-inline-flex password-input-width  ">
                <input
                  type={showPassword ? "text" : "password"}
                  className="formFieldInput  "
                  name="password password-input-width"
                  id="password"
                  value={password}
                  placeholder="Password"
                  onChange={(e) => setPassword(e.target.value)}
                  maxLength={25}
                />

                <button
                  type="button"
                  className="btn btn-primary btn-sm  password-eye-btn"
                >
                  <Icon
                    icon="typcn:eye"
                    color="black"
                    onClick={(e) => togglePassword(e)}
                  />
                </button>
              </div>

              <div className="mt-1 d-flex justify-content-end">
                <NavLink to="/resetpassword" className="forget-password-link ">
                  Forget password?{" "}
                </NavLink>
              </div>
            </div> */}

            <div className="formField">
              <label className="formFieldLabel" htmlFor="password">
                Confirm Password
              </label>

              <div className="d-inline-flex password-input-width  ">
                <input
                  type={showPassword ? "text" : "password"}
                  className="formFieldInput  "
                  name="password password-input-width"
                  id="confirm password"
                  placeholder="confirm password"
                  onChange={(e) => setPasswordAgain(e.target.value)}
                />
                <button
                  type="button"
                  className="btn btn-primary btn-sm password-eye-btn"
                  onClick={(e) => togglePassword(e)}
                >
                  <Icon
                    className="icon-button"
                    icon="typcn:eye"
                    color="black"
                    // onClick={(e) => togglePassword(e)}
                  />
                </button>
              </div>
              <div className="mt-3">
                <PasswordChecklist
                  rules={[
                    "minLength",
                    "specialChar",
                    "number",
                    "capital",
                    "match",
                  ]}
                  minLength={8}
                  value={password}
                  valueAgain={passwordAgain}
                  onChange={(isValid) => {}}
                />
              </div>
            </div>
            {/* 
            <div className="formField">
              <label className="formFieldLabel" htmlFor="password">
                Confirm Password
              </label>

              <div className="d-inline-flex password-input-width  ">
                <input
                  type={showPassword ? "text" : "password"}
                  className="formFieldInput  "
                  name="password password-input-width"
                  id="confirm password"
                  placeholder="confirm password"
                  onChange={(e) => setPasswordAgain(e.target.value)}
                />

                <PasswordChecklist
                  rules={[
                    "minLength",
                    "specialChar",
                    "number",
                    "capital",
                    "match",
                  ]}
                  minLength={8}
                  value={password}
                  valueAgain={passwordAgain}
                  onChange={(isValid) => {}}
                />
                <button
                  type="button"
                  className="btn btn-primary btn-sm  password-eye-btn"
                >
                  <Icon
                    icon="typcn:eye"
                    color="black"
                    onClick={(e) => togglePassword(e)}
                  />
                </button>
              </div>
            </div> */}
            <div className="row formField btn-field d-flex d-flex jsutify-content-center ">
              <div classNmae="col-lg-6 col-md-6 col-sm-12 col-xs-12 m-4">
                <button className="formFieldButton login-btn-form ">
                  Sign In
                </button>{" "}
              </div>
              <div classNmae="col-lg-6 col-md-6 col-sm-12 col-xs-12 m-4">
                {/* <a
                href="/"
                target="_blank"
                className="formFieldButton login-btn-form "
              >
                Create Password
              </a> */}

                <NavLink to="/">
                  <button className="formFieldButton login-btn-form ">
                    Create New Password
                  </button>
                </NavLink>
              </div>
            </div>

            {/* <div className="formField btn-field  ">
              <button className="formFieldButton login-btn-form ">
                Sign In
              </button>{" "}
              <a
                href="/"
                target="_blank"
                className="formFieldButton login-btn-form"
              >
                Create Password
              </a>
            </div> */}
          </form>
        </div>
      </div>
      <div className="d-flex justify-content-center mt-2 align-items-end">
        <a href="https://react.opsways.com/" target="_blank ">
          <img src={Logo} alt="opsways" className="footoer-logo" />
        </a>
      </div>
    </div>
  );
}
