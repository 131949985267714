import { Icon } from "@iconify/react";
import React, { useState } from "react";
import { Form } from "react-bootstrap";
import PasswordChecklist from "react-password-checklist";
import { NavLink } from "react-router-dom";

export default function SettingForgetPassword() {
  const [password, setPassword] = useState("");
  const [passwordAgain, setPasswordAgain] = useState("");
  const [showPassword, setShowPassword] = useState("");
  const [email, setEmail] = useState("");
  const BASE_URL = process.env.REACT_APP_API_BASE_URL;

  const togglePassword = (e) => {
    if (showPassword) {
      setShowPassword(false);
    } else {
      setShowPassword(true);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = {
      email,
      password,
    };
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Bearer " + JSON.parse(sessionStorage.getItem("token")).data.token,
      },
      body: JSON.stringify(token),
    };
    const response = await fetch(
      `${BASE_URL}/email/createnewpassword`,
      requestOptions
    );
    const data = await response.json();
    sessionStorage.setItem("token", JSON.stringify(data));
    // console.log(data);
    // console.log(data.data.token);
    if (data.success === true) {
      alert("Password Updated Successfully");
      window.location = "/user-page/login";
    } else {
      alert("Something Went Wrong");
    }
  };
  return (
    <>
      <div className="container-fluid ">
        <div className="container d-flex justify-content-center">
          <div className="row m-3 w-100 ">
            <div className="col-lg-3"></div>
            <div className="card card-shadow card-left col-lg-6 d-flex justify-content-center  ">
              <div>
                <h5 className="mt-5 text-center fontting">
                  Create New Password{" "}
                </h5>
              </div>
              <div>
                <Form className="formFields mt-3" onSubmit={handleSubmit}>
                  <Form.Group className="formField">
                    <label
                      className="formFieldLabel fontting-label "
                      htmlFor="email"
                    >
                      Confirm E-Mail Address
                    </label>
                    <Form.Control
                      type="email"
                      id="email"
                      className="formFieldInput"
                      placeholder="Enter your email"
                      value={email}
                      size="lg"
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </Form.Group>

                  <Form.Group className="formField">
                    <label
                      className="formFieldLabel fontting-label"
                      htmlFor="password"
                    >
                      Password
                    </label>

                    <div className="d-inline-flex password-input-width  ">
                      <Form.Control
                        type={showPassword ? "text" : "password"}
                        className="formFieldInput  "
                        name="password password-input-width"
                        id="password"
                        value={password}
                        placeholder="Password"
                        onChange={(e) => setPassword(e.target.value)}
                        maxLength={25}
                      />

                      <button
                        type="button"
                        className="btn btn-primary btn-sm password-eye-btn"
                        onClick={(e) => togglePassword(e)}
                      >
                        <Icon
                          className="icon-button"
                          icon="typcn:eye"
                          color="black"
                          //onClick={(e) => togglePassword(e)}
                        />
                      </button>
                    </div>
                  </Form.Group>

                  <Form.Group className="formField">
                    <label
                      className="formFieldLabel fontting-label"
                      htmlFor="password"
                    >
                      Confirm password
                    </label>
                    <div className="d-inline-flex password-input-width">
                      <Form.Control
                        type={showPassword ? "text" : "password"}
                        className="formFieldInput  "
                        name="password password-input-width"
                        id="confirm password"
                        placeholder="confirm password"
                        onChange={(e) => setPasswordAgain(e.target.value)}
                      />
                      <button
                        type="button"
                        className="btn btn-primary btn-sm password-eye-btn"
                        onClick={(e) => togglePassword(e)}
                      >
                        <Icon
                          className="icon-button"
                          icon="typcn:eye"
                          color="black"
                        />
                      </button>
                    </div>
                    <div className="mt-3">
                      <PasswordChecklist
                        rules={[
                          "minLength",
                          "specialChar",
                          "number",
                          "capital",
                          "match",
                        ]}
                        minLength={8}
                        value={password}
                        valueAgain={passwordAgain}
                        onChange={(isValid) => {}}
                      />
                    </div>
                  </Form.Group>

                  <div className="row formField btn-field d-flex d-flex jsutify-content-center  ">
                    <div classNmae="col-lg-6 col-md-6 col-sm-12 col-xs-12 m-4">
                      <NavLink to="/">
                        <button className="formFieldButton login-btn-form ">
                          Submit
                        </button>
                      </NavLink>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
