import NoproductCard from "Pages/no-product";
import React from "react";
import { Card } from "react-bootstrap";

export default function ReviewsPage() {
  return (
    <>
      <div classname="container">
        <div className="d-flex  d-flex align-items-center m-2">
          <Card>
            <Card.Body>
              <Card.Title className="d-flex justify-content-center">
                <h1>Review!</h1>
              </Card.Title>
              <Card.Body>
                <p className="not-found-404 text-center fs-1 fw-bold">
                  Jodie The Hamptons, NY{" "}
                </p>
                <p className="not-found-404 text-center fs-1 fw-bold">
                  ❝ Amazing! Some of the best massages I have ever had.
                  Definitely recommend as a fun alternative to going to a spa. ❞
                </p>
              </Card.Body>
            </Card.Body>
          </Card>
          <Card className="ml-3">
            <Card.Body>
              <Card.Title className="d-flex justify-content-center">
                <h1>Review!</h1>
              </Card.Title>
              <Card.Body>
                <p className="not-found-404 text-center fs-1 fw-bold">
                  Jodie The Hamptons, NY{" "}
                </p>
                <p className="not-found-404 text-center fs-1 fw-bold">
                  ❝ Amazing! Some of the best massages I have ever had.
                  Definitely recommend as a fun alternative to going to a spa. ❞
                </p>
              </Card.Body>
            </Card.Body>
          </Card>
          <Card className="ml-3">
            <Card.Body>
              <Card.Title className="d-flex justify-content-center">
                <h1>Review!</h1>
              </Card.Title>
              <Card.Body>
                <p className="not-found-404 text-center fs-1 fw-bold">
                  Jodie The Hamptons, NY{" "}
                </p>
                <p className="not-found-404 text-center fs-1 fw-bold">
                  ❝ Amazing! Some of the best massages I have ever had.
                  Definitely recommend as a fun alternative to going to a spa. ❞
                </p>
              </Card.Body>
            </Card.Body>
          </Card>
        </div>
      </div>
    </>
  );
}
