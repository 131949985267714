import moment from "moment-timezone";
import ReactPaginate from "react-paginate";
import React, { useEffect, useState } from "react";
import Preloader from "assets/images/preloader.gif";
import { Icon } from "@iconify/react";
export default function CancelSubscription() {
  const [subscription, setSubscription] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const [loading, setloading] = useState(undefined);
  const [completed, setcompleted] = useState(undefined);
  const usersPerPage = 10;
  const pagesVisited = pageNumber * usersPerPage;
  const pageCount = Math.ceil(subscription.length / usersPerPage);

  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };
  const BASE_URL = process.env.REACT_APP_API_BASE_URL;

  useEffect(() => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Bearer " + JSON.parse(sessionStorage.getItem("token")).data.token,
      },
    };
    fetch(
      `${BASE_URL}/subscription/unsubscribe/${
        JSON.parse(sessionStorage.getItem("token")).data.clientuserid
      }`,
      requestOptions
    )
      .then((response) => response.json())
      .then((response) => {
        setSubscription(response.data.clientUnSubscription);
        setTimeout(() => {
          setcompleted(true);
        }, 1000);
        setloading(false);

        //console.log(response.data.clientUnSubscription);
      });
  }, []);
  return (
    <div>
      <div className="col-xl-12 stretch-card">
        <div className="card card-top">
          <div className="card-body">
            <h3>
              <span className="menu-icon">
                <i
                  className="mdi mdi-wunderlist"
                  style={{ color: "#ddbc6b" }}
                ></i>{" "}
              </span>{" "}
              In-Active Subscriptions
            </h3>
            <div className="table-responsive text-center">
              <table className="table table-hover" style={{ overflow: "auto" }}>
                <thead className="table table-header">
                  <tr>
                    <th> Product Name </th>
                    <th> Subscription </th>
                    <th> Date </th>
                    <th> Status </th>
                  </tr>
                </thead>
                {!completed ? (
                  <>
                    {!loading ? (
                      <div className="d-flex justify-content-md-center">
                        <div className="spinner text-center ">
                          {/* <img
                            src={Preloader}
                            alt="preloader"
                            className="img-lodder"
                          /> */}
                          <br />
                          <span className="span_title">
                            <h6>Loading </h6>
                          </span>
                          <span>
                            <Icon
                              icon="el:refresh"
                              color="#33a280"
                              width={20}
                              height={20}
                            />
                          </span>
                          <br />
                        </div>
                      </div>
                    ) : (
                      <div className="completed">&#x2713;</div>
                    )}
                  </>
                ) : (
                  <tbody>
                    {subscription
                      .slice(pagesVisited, pagesVisited + usersPerPage)
                      .reverse()
                      .map((mess) => (
                        <tr className="table-success">
                          <td>{mess.productname}</td>
                          <td>{mess.subscriptionperiod} Month</td>
                          <td>{moment(mess.created_at).format("D-MM-YYYY")}</td>
                          <td>
                            <label
                              className="badge badge-success"
                              style={{
                                backgroundColor: mess.is_active
                                  ? "#ddbc6b"
                                  : "red",
                              }}
                            >
                              {mess.is_active ? "Active" : "In Active"}
                            </label>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                )}
              </table>
            </div>
            <div className="mt-5">
              <ReactPaginate
                previousLabel={"<<"}
                nextLabel={">>"}
                breakLabel={"..."}
                pageCount={pageCount}
                onPageChange={changePage}
                containerClassName={"pagination justify-content-center"}
                pageClassName={"page-item"}
                pageLinkClassName={"page-link"}
                previousClassName={"page-item"}
                previousLinkClassName={"page-link"}
                nextClassName={"page-item"}
                nextLinkClassName={"page-link"}
                breakClassName={"page-item"}
                breakLinkClassName={"page-link"}
                activeClassName={"active"}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
