import React, { useEffect, useState } from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import "./App.scss";
import Main from "Pages/user-pages/Main";
import ResetPassword from "Pages/user-pages/ForgetPassword";
import Confirmation from "Pages/user-pages/ForgetGenerateOTP";
import CreateNewPassword from "Pages/user-pages/CreateNewPassword";
import Dashboard from "Pages/dashboard/Dashboard";
import Settings from "Pages/settings";
import Error500 from "Pages/error-pages/Error500";
import Error404 from "Pages/error-pages/Error404";
import Register from "Pages/user-pages/Register/Register";
import InvoiceTable from "Pages/invoices/InvoiceTable";
import Viewinvoice from "Pages/invoices/viewinvoice";
import AppLayout from "components/Layout/Layout";
import ReactGA from "react-ga";
import OtpConfirm from "Pages/user-pages/OtpConfirm";
import SettingForgetPassword from "Pages/settings/ForgetPassword";
import Subscription from "Pages/subscription";
import CancelSubscription from "Pages/subscription/cancelSubscription";
import ReviewsPage from "Pages/ReviewsPage";
import Offers from "Pages/Offers";
import Bio from "Pages/settings/Bio";
import PreviewProfile from "Pages/settings/PreviewProfile";
import TermsConditions from "Pages/TermsConditions";
import PrivacyPolicy from "Pages/PrivacyPolicy";
const TRACKING_ID = "UA-254757725-1"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);
const App = () => {
  // const [isLogged, setisLogged] = useState(false);
  const BASE_URL = process.env.REACT_APP_API_BASE_URL;

  // useEffect(() => {
  //   ReactGA.pageview(window.location.pathname + window.location.search);

  //   const handleBeforeUnload = (event) => {
  //     if (isLogged) {
  //       event.preventDefault();
  //       event.returnValue = ""; // This will show the confirmation message
  //       logtime();
  //     }
  //   };

  //   if (isLogged) {
  //     window.addEventListener("beforeunload", handleBeforeUnload);
  //   }

  //   return () => {
  //     window.removeEventListener("beforeunload", handleBeforeUnload);
  //   };
  // }, [isLogged]);

  const logtime = () => {
    const data = {
      loginid: JSON.parse(sessionStorage.getItem("logtime")),
      logout_time: new Date(),
    };

    const requestOptions = {
      method: "PUT",

      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Bearer " + JSON.parse(sessionStorage.getItem("token")).data.token,
      },
      body: JSON.stringify(data),
    };
    fetch(`${BASE_URL}/log/edit`, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        // console.log(response);
        // sessionStorage.clear();
        // window.location = "/";
      });
  };

  // useEffect(() => {
  //   const token = sessionStorage.getItem("token");
  //   if (token) {
  //     setisLogged(true);
  //   }
  // }, []);

  return (
    <Switch>
      <Route exact path="/">
        <Main />
      </Route>
      <Route path="/resetpassword">
        <ResetPassword />
      </Route>
      <Route path="/generateotp">
        <Confirmation />
      </Route>
      <Route path="/createnew">
        <CreateNewPassword />
      </Route>
      <Route path="/otpconfirm">
        <OtpConfirm />
      </Route>
      <Route path="/createpassword" component={SettingForgetPassword} />

      <AppLayout>
        <Route
          component={({ match }) => (
            <div>
              {/* <Route
                path="/operations/dedicatedoperation"
                component={Dedicatedoperation}
              />

              <Route
                path="/operations/sharedoperation"
                component={SharedOperation}
              />
              <Route
                path="/operations/securityoperation"
                component={Securityoperation}
              />
             
              <Route
                path="/createmailhosting"
                component={CreateEmailHostingOrder}
              />
              <Route
                path="/upgradecloud/:instance_id/:order_id/:productid"
                component={UpgradeCloudOperation}
              />
              <Route
                path="/upgradeshared/:order_id/:productid/:username"
                component={UpdateSharedOrder}
              /> */}
              <Route
                path="/viewinvoice/:order_id/:invoice_id"
                component={Viewinvoice}
              />
              <Route path="/reviews" component={ReviewsPage} />
              <Route path="/dashboard" component={Dashboard} />
              <Route
                path="/subscription/subscription"
                component={Subscription}
              />
              <Route
                path="/subscription/cancelsubscription"
                component={CancelSubscription}
              />
              <Route path="/offers/offers" component={Offers} />
              <Route path="/terms/terms" component={TermsConditions} />
              <Route path="/terms/privacy" component={PrivacyPolicy} />
              

              {/* <Route
                path="/operations/cloudoperation"
                component={Cloudoperation}
              />
              <Route path="/quote/requestaquote" component={Requestaquote} />
              <Route path="/howtos" component={Howtos} />
              <Route path="./refermodal" component={ReferModal} />
              <Route path="/tickets" component={Tickets} />
              <Route path="/addons/addons" component={Addons} />
              <Route path="/services/oneclickapp" component={OneClickApp} />
              <Route path="/services/listwebsites" component={Websites} />
              <Route path="/services/clonning" component={Clonning} />
              <Route path="/db-engine/databases" component={Databases} /> */}
              <Route path="/invoices/InvoiceTable" component={InvoiceTable} />
              <Route exact path="/user-pages/register-1" component={Register} />
              <Route path="/error-pages/error-404" component={Error404} />
              <Route path="/error-pages/error-500" component={Error500} />
              <Route path="/settings/settings" component={Settings} />
              <Route path="/settings/bio" component={Bio} />
              <Route path="/settings/preview" component={PreviewProfile} />
              {/* <Route path="/logs/logreports" component={LogReports} />
              <Route path="/logs/events" component={Events} />
              <Route path="/notification" component={Notify} />
              <Route path="/frequentlyaskequestion" component={FAQ} />
              <Route path="/success" component={PaymentSuccess} />
              <Route path="/reply/:id/:authorid" component={Reply} />
              <Route path="/dns" component={DNS} />
              <Route path="/smtp" component={SMTPSrvice} />
              <Route path="/emailhosting" component={Email} />
              <Route path="/createorder" component={CreateCloudOrder} />
              <Route path="/createsmtp" component={CreateSMTPOrder} />
              <Route path="/sharedorder" component={CreateSharedOrder} />
              <Route path="/dedicatedorder" component={CreateDedicatedOrder} />
              <Route path="/securityorder" component={CreateSecurityOrder} />
              <Route path="/addonorder" component={CreateAddonOrder} /> */}
            </div>
          )}
        />
      </AppLayout>
    </Switch>
  );
};

export default withRouter(App);
