import React from "react";
import { Card } from "react-bootstrap";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

export default function PrivacyPolicy() {
  return (
    <div>
      {" "}
      <Card style={{ width: "auto", height: "auto" }}>
        <Card.Body>
          <Card.Title style={{ color: "#ddbc6b", fontSize: 30 }}>
            {" "}
            Privacy Policy
          </Card.Title>
          <h4>
            <b>Evenglos Privacy Policy - United States</b>
          </h4>
          {/* <img
        src={IMG}
        style={{ height: "100%", width: "100%" }}
        alt="promotion"
      /> */}
          <p>
            Welcome to the website and/or mobile application of Evenglos, Inc.
            (“Evenglos”, “we”, “us” and/or “our”). We have prepared this Privacy
            Policy to explain how we collect, use, protect and disclose
            information and data when you use the Evenglos website (“Website”)
            and any Evenglos software, including any Evenglos mobile
            applications (the “App(s)”) or other services offered by Evenglos
            from time to time that reference or link to this Privacy Policy
            (collectively, the Website(s), App(s) and services are referred to
            as “Services”). “You”refers to you as a user of the Services.
          </p>
          <p>
            For the purpose of the Data Protection Directive 95/46/EC (the
            Directive), the data controller is Evenglos, Inc. of Address, Phone.
          </p>
          <p>
            <b>
              Please read the following carefully to understand our views and
              practices regarding your personal data and how we will treat it.
              We draw your attention in particular to “International Data
              Transfer” and “Communications.” BY USING THE SERVICES YOU CONSENT
              TO THIS PRIVACY POLICY.
            </b>
          </p>
          <p>
            <Link to="#">Background/Managing Your Information Preferences</Link>
          </p>
          <p>
            Evenglos provides an online platform that connects massage
            therapists (“Therapists”) with individuals that want to purchase
            and/or receive massage therapy including, without limitation,
            website visitors (“Customers”). You can always review, correct, or
            update your Personal Information by changing the relevant settings
            in your Evenglos account. Applicable law (such as the Directive) may
            also give you the right to access, update and correct inaccuracies
            in information about you in our custody or control. If you wish to
            exercise this right, please contact us at privacy@Evenglos.com.
          </p>
          <Card.Link href="/offers/offers"></Card.Link>
        </Card.Body>
      </Card>
    </div>
  );
}
